// source: pbtypes/gen/cloud_api/cloud_error_code.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.cloud_api.CloudErrorCode', null, global);
goog.exportSymbol('proto.cloud_api.CloudErrorCode.Enum', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.cloud_api.CloudErrorCode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.cloud_api.CloudErrorCode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.cloud_api.CloudErrorCode.displayName = 'proto.cloud_api.CloudErrorCode';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.cloud_api.CloudErrorCode.prototype.toObject = function(opt_includeInstance) {
  return proto.cloud_api.CloudErrorCode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.cloud_api.CloudErrorCode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.CloudErrorCode.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.cloud_api.CloudErrorCode}
 */
proto.cloud_api.CloudErrorCode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.cloud_api.CloudErrorCode;
  return proto.cloud_api.CloudErrorCode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.cloud_api.CloudErrorCode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.cloud_api.CloudErrorCode}
 */
proto.cloud_api.CloudErrorCode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.cloud_api.CloudErrorCode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.cloud_api.CloudErrorCode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.cloud_api.CloudErrorCode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.cloud_api.CloudErrorCode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * @enum {number}
 */
proto.cloud_api.CloudErrorCode.Enum = {
  SUCCESS: 0,
  CLOUD_API_ERROR: 1000,
  DATABASE_ERROR: 1100,
  THIRD_PARTY_ERROR: 1200,
  LOCK_TIMEOUT_ERROR: 1300,
  RATE_LIMIT_EXCEEDED_ERROR: 1429,
  PERMISSIONS_ERROR: 2000,
  JUSTIFICATION_NEEDED: 2010,
  UNAUTHENTICATED: 3000,
  USER_REQUIRES_SSO: 3050,
  REFRESH_REQUIRED: 3100,
  BAD_LOGIN_CODE: 3200,
  INVALID_TOKEN: 3300,
  INVITE_LINK_EXPIRED: 3400,
  NOT_FOUND: 4100,
  VEHICLE_NOT_FOUND: 4200,
  DOCK_NOT_FOUND: 4250,
  ACCESS_POINT_NOT_FOUND: 4270,
  DEVICE_NOT_FOUND: 4300,
  ALERT_NOT_FOUND: 4340,
  ALERT_CONFIG_NOT_FOUND: 4345,
  WEBHOOK_NOT_FOUND: 4360,
  FLIGHT_NOT_FOUND: 4400,
  BATTERY_NOT_FOUND: 4450,
  CONTROLLER_NOT_FOUND: 4460,
  GIMBAL_NOT_FOUND: 4470,
  ATTACHMENT_NOT_FOUND: 4480,
  USER_NOT_FOUND: 4500,
  ORGANIZATION_NOT_FOUND: 4550,
  WIFI_SETTING_NOT_FOUND: 4555,
  API_TOKEN_NOT_FOUND: 4575,
  AXON_CONFIG_NOT_FOUND: 4580,
  RESOURCE_GROUP_NOT_FOUND: 4590,
  RELEASE_NOT_FOUND: 4600,
  RELEASE_VEHICLE_COMBO_NOT_FOUND: 4601,
  SIMULATOR_NOT_FOUND: 4650,
  FLIGHT_DATA_FILE_NOT_FOUND: 4700,
  DEVICE_DATA_FILE_NOT_FOUND: 4750,
  ORGANIZATION_FILE_NOT_FOUND: 4760,
  FILE_SIZE_LIMIT_EXCEEDED: 4770,
  UCON_FILE_INTEGRATION_NOT_FOUND: 4780,
  APP_VERSION_NOT_FOUND: 4800,
  GROUP_NOT_FOUND: 4900,
  SKILLSET_NOT_FOUND: 4950,
  SKILLSET_VERSION_NOT_FOUND: 4960,
  MOBILE_APP_NOT_FOUND: 4975,
  SKILLSET_MOBILE_APP_LINK_NOT_FOUND: 4980,
  DCON_FILE_NOT_FOUND: 4350,
  SKILLSET_DCON_LINK_NOT_FOUND: 4375,
  SCAN_NOT_FOUND: 4551,
  LIVESTREAM_SETTINGS_NOT_FOUND: 4995,
  MISSION_TEMPLATE_NOT_FOUND: 4996,
  BAD_REQUEST: 5000,
  BAD_ARGS: 5100,
  SCHEMA_VALIDATION_ERROR: 5110,
  METHOD_NOT_ALLOWED: 5200,
  INVALID_VEHICLE_ID: 5300,
  INVALID_VEHICLE_TYPE: 5301,
  INVALID_DOCK_ID: 5350,
  RELEASE_NOT_ACTIVE: 5400,
  GROUP_IS_PERMISSION: 5401,
  GROUP_NOT_RELEASE_GROUP: 5402,
  INVALID_DCON_TOKEN: 5500,
  INVALID_SIMULATOR_CREDENTIALS: 5600,
  NO_VEHICLE_KEY_FOUND: 5700,
  NO_DOCK_KEY_FOUND: 5750,
  NO_ACCESS_POINT_AUXILIARY_KEY_FOUND: 5800,
  INVALID_EMAIL: 6000,
  BOUNCED_EMAIL: 6100,
  COMPLAINED_EMAIL: 6200,
  INTEGRITY_ERROR: 7000,
  INCONSISTENT_ORGANIZATION: 7001,
  OVERWRITE_PROTECTED: 7100,
  DUPLICATE_FILE_KEY: 7200,
  INVALID_FILE_KEY: 7210,
  FILE_SIZE_INTEGRITY_ERROR: 7250,
  BUNDLE_SKILLSET_RESTRICTED: 7300,
  NO_POOLED_SIMS_AVAILABLE: 7400,
  DECRYPTION_FAILED: 7500,
  VEHICLE_DISABLED: 7600,
  USER_PART_OF_OTHER_ORG: 7700,
  DEVICE_OFFLINE: 7800,
  EXTERNAL_TELEMETRY_ERROR: 8000,
  VEHICLE_TELEOP_DISABLED: 8100,
  STRUCTURE_NOT_FOUND: 8200
};

goog.object.extend(exports, proto.cloud_api);
