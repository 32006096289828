import _ from "lodash";

import { CloudErrorCode } from "@skydio/pbtypes/pbtypes/gen/cloud_api/cloud_error_code_pb";
import { FlaskAPIResponse } from "@skydio/pbtypes/pbtypes/util/flask_app/api_responses_pb";

import { FetchError } from "../../common/http";

export const LOCAL_CLOUD_API_URL = "http://localhost:7000";
export const isDeployedCloudApi = (apiUrl: string) => apiUrl !== LOCAL_CLOUD_API_URL;

const getErrorCodeName = (code: number) => _.findKey(CloudErrorCode.Enum, val => val === code);

export function checkStatus(response: Response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const error = new FetchError(response.statusText);
  error.code = response.status.toString();
  error.response = response;
  throw error;
}

export function checkAPIStatus(data: any, isGraphQLRequest: boolean = false) {
  let code: number, hasError: boolean, errorMsg: string;

  if (data instanceof FlaskAPIResponse) {
    // protobuf response
    code = data.getCode();
    hasError = data.hasError();
    errorMsg = getErrorCodeName(code) ?? "";
    if (code === CloudErrorCode.Enum.SUCCESS && !hasError) {
      return data.getData();
    }
  } else if (data instanceof Response) {
    // raw response object
    return data;
  } else {
    // json response
    code = data.code;
    hasError = data.error;
    errorMsg = data.error?.msg || getErrorCodeName(code);
    // Successful graphql responses do not have codes, so bypass the code check for empty codes
    if ((code === CloudErrorCode.Enum.SUCCESS || (isGraphQLRequest && !code)) && !hasError) {
      return data.data;
    }
  }

  if (code !== CloudErrorCode.Enum.SUCCESS || hasError) {
    const error = new FetchError(`[${code}]: ${errorMsg}`);
    error.code = String(code);
    throw error;
  }
}

export async function parseResponse(response: Response) {
  switch (response.headers.get("Content-Type")) {
    case "application/json":
      const json = await response.json();
      return json;
    case "application/x-protobuf":
      const buffer = await response.arrayBuffer();
      const proto = FlaskAPIResponse.deserializeBinary(new Uint8Array(buffer));
      return proto;
    default:
      return response;
  }
}

export function isRefreshRequiredError(error: unknown) {
  return (
    error instanceof FetchError &&
    error.code &&
    parseInt(error.code) === CloudErrorCode.Enum.REFRESH_REQUIRED
  );
}
